
import { Component, Vue } from 'vue-property-decorator';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';

@Component({
    name: 'LoginModalFooter',
    components: { },
})
export default class LoginModalFooter extends Vue {

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get newCustomerUrl(): string {
        return CbxVariablesModule.VARIABLES.newCustomerPageUrl;
    }
}
