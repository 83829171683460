import localforage from 'localforage';
import CachedItem from '@/models/CachedItem';

export default class CacheService {
    private profileId: string = (window as any)?.CbxApiContextKey ?? '';

    constructor(args: any = {}) {
        if (args.profileId) this.profileId = args.profileId;
    }

    // vue cache
    public async getCachedItem(cacheKey: string): Promise<any> {
        const item: CachedItem = await localforage.getItem(this.getKey(cacheKey));
        if (item !== null) {
            if (this.hasExpired(item)) {
                await this.clearCachedItem(cacheKey);
                return null;
            }
            return item.value;
        }
        return null;
    }

    public async setCachedItem(cacheKey: string, value: any) {
        const payload: CachedItem = {
            expires: this.getExpiration(),
            value,
        };
        await localforage.setItem(this.getKey(cacheKey), payload);
    }

    public async clearCachedItem(cacheKey: string) {
        await localforage.removeItem(this.getKey(cacheKey));
    }

    public getExpiration(minutes: number = 10): number {
        const d = new Date();
        d.setMinutes(d.getMinutes() + minutes);
        return d.getTime();
    }

    public hasExpired(item: CachedItem): boolean {
        return item.expires < new Date().getTime();
    }

    private getKey(cacheKey: string): string {
        return `${this.profileId}_${cacheKey}`;
    }
}
