
import { Component, Vue } from 'vue-property-decorator';
import MobileHeaderSearch from '@/components/mobile-header/MobileHeaderSearch.vue';
import { CbxVariablesModule } from '../../store/modules/CbxVariablesModule';
import MobileHeaderSearchOverlay from './MobileHeaderSearchOverlay.vue';

@Component({
    name: 'MobileHeaderBottom',
    components: {
        MobileHeaderSearch,
        MobileHeaderSearchOverlay,
    },
})
export default class MobileHeaderBottom extends Vue {

    get disableCatalogue(): boolean {
        return CbxVariablesModule.VARIABLES.disableCatalogue;
    }

    get useMagicSearch(): boolean {
        return CbxVariablesModule.VARIABLES.enableMagicSearch;
    }
}
