import { WebCartItem } from '@/models/Cart';
import CartModule from '@/store/modules/CartModule';
import CheckoutModule from '@/store/modules/CheckoutModule';
import { TrackingModule } from '@/store/modules/TrackingModule';

export default class TrackingService {

    public trackAddToCart(item: WebCartItem) {

        if (!item)
            return;
        // facebook pixel tracking
        if (typeof (window as any).fbq === 'function')
        {
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));

            (window as any).fbq('track', 'AddToCart', {
                'content_name': item.name,
                'content_category': '',
                'content_ids': [item.productid],
                'content_type': 'product',
                'value': unitpriceincvat.toFixed(2),
                'currency': item.unitprice.currency
            });
        }

        // ga4 tracking
        if (typeof (window as any).gtag === 'function')
        {
            const linepriceincvat = item.unitprice.price * item.quantity * (1 + (item.unitprice.vatrate / 100));
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            const unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

            (window as any).gtag('event', 'add_to_cart', {
                currency: item.unitprice.currency,
                items: [{
                  'item_id': item.productid,
                  'item_name': item.name,
                  coupon: '',
                  discount: unitpricediscountamountincvat.toFixed(2),
                  affiliation: '',
                  'item_brand': '',
                  'item_category': '',
                  'item_variant': '',
                  price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                  currency: item.unitprice.currency,
                  quantity: item.quantity
                }],
                value: linepriceincvat.toFixed(2)
            });
        }

        if (TrackingModule.ALLOW_MARKETING && typeof (window as any).klaviyo === "object")
        {
            const cart = CartModule.CART;
            const carttotalincvat = cart.items.reduce((output, item) => output + (item.lineprice.price * (1 + (item.lineprice.vatrate / 100))), 0);
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            let imageurl = item.imageurl;
            if (imageurl.startsWith("/"))
                imageurl = (window as any).klaviyoVarsHostname + imageurl;

            (window as any).klaviyo.track("Added to Cart", {
                "$value": +carttotalincvat.toFixed(2),
                "AddedItemProductName": item.name,
                "AddedItemProductID": item.productid,
                "AddedItemSKU": item.productid,
                "AddedItemCategories": item.productcategories,
                "AddedItemImageURL": imageurl,
                "AddedItemURL": (window as any).klaviyoVarsHostname + item.producturl,
                "AddedItemPrice": +unitpriceincvat.toFixed(2),
                "AddedItemQuantity": item.quantity,
                "ItemNames": cart.items.map(cartitem => cartitem.name),
                "CheckoutURL": (window as any).klaviyoVarsCheckoutUrl,
                "Items": [
                    cart.items.map(cartitem => {
                        const _linepriceincvat = cartitem.unitprice.price * cartitem.quantity * (1 + (cartitem.unitprice.vatrate / 100));
                        const _unitpriceincvat = cartitem.unitprice.price * (1 + (cartitem.unitprice.vatrate / 100));

                        let _imageurl = cartitem.imageurl;
                        if (_imageurl.startsWith("/"))
                            _imageurl = (window as any).klaviyoVarsHostname + _imageurl;

                        return {
                            "ProductID": cartitem.productid,
                            "SKU": cartitem.productid,
                            "ProductName": cartitem.name,
                            "Quantity": cartitem.quantity,
                            "ItemPrice": +_unitpriceincvat.toFixed(2),
                            "RowTotal": +_linepriceincvat.toFixed(2),
                            "ProductURL": (window as any).klaviyoVarsHostname + cartitem.producturl,
                            "ImageURL": _imageurl,
                            "ProductCategories": cartitem.productcategories,                           
                        }
                    })
                ]
            });
        }
    }

    public async trackLogin(email: string) {

        if (!email?.length)
            return;

        if (TrackingModule.ALLOW_MARKETING && typeof (window as any).klaviyo === "object")
        {
            const isIdentified: boolean = await (window as any).klaviyo.isIdentified();                        

            if (isIdentified)
                return;

            await (window as any).klaviyo.identify({
                "$email" : email,
                "B2B" : true,
            });
        }

        return;
    }

    public async trackSubscribe(email: string) {

        if (!email?.length)
            return;

        if (TrackingModule.ALLOW_MARKETING && typeof (window as any).klaviyo === "object")
        {
            const isIdentified: boolean = await (window as any).klaviyo.isIdentified();                        

            if (isIdentified)
                return;
            
            await (window as any).klaviyo.identify({
                "$email" : email,
            });
        }

        return;
    }

    public async trackCheckout() {
        if (TrackingModule.ALLOW_MARKETING && typeof (window as any).klaviyo === "object")
        {
            const address = CheckoutModule.ADDRESS;
            if (!address.email?.length)
                return;
            
            (window as any).klaviyo.isIdentified().then(async (isIdentified: boolean) => {

                if (!isIdentified)
                {
                    await (window as any).klaviyo.identify({
                        "$email" : address.email,
                    });

                    await (window as any).klaviyo.track("Started Checkout", (window as any).klaviyoVarsStartedCheckoutEvent);
                }
            });                        
            
            await new Promise(resolve => setTimeout(resolve, 600));
        }

        return;
    }
}
