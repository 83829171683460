import { Module, VuexModule, getModule, Action } from 'vuex-module-decorators';
import store from '@/store';
import { ViewEventTypeModel } from '@/models/EventTracking';
import EventTrackingService from '@/services/EventTrackingService';

@Module({
    store,
    dynamic: true,
    name: 'eventTracking',
    namespaced: true,
})
class EventTrackingModule extends VuexModule {
    private ets: EventTrackingService = new EventTrackingService();

    @Action
    public TRACK_VIEW(args: ViewEventTypeModel) {
        this.ets.trackView(args);
    }
}

export default getModule(EventTrackingModule);
