
import { WebCartItem } from '@/models/Cart';
import CartService from '@/services/CartService';
import CartModule from '@/store/modules/CartModule';
import { Component, Vue } from 'vue-property-decorator';
import Alert from '../utils/Alert.vue';

const cs: CartService = new CartService();

@Component({
    name: 'LoginCartActions',
    components: {
        Alert,
    },
})
export default class LoginCartActions extends Vue {

    private retentionCartItems: WebCartItem[] = [];

    get placeholderImage(): string {
        return (window as any).CbxPlaceholderImage;
    }

    get visibleRetentionCartItems(): WebCartItem[] {
        return this.retentionCartItems?.filter(item => !item.addonparent) ?? [];
    }

    get itemsInCart(): boolean {
        return CartModule.CART.items.length > 0;
    }

    private addonItems(cartitem: WebCartItem): WebCartItem[] {
        return this.retentionCartItems?.filter(item => item.addonparent == cartitem.productid && item.variantid == cartitem.variantid) ?? [];
    }

    private async change(merge: boolean = false) {
        await CartModule.CHANGE_CART(merge);
        this.$emit('close-modal');
    }

    private async reload() {
        window.location.reload();
    }

    private async created() {
        this.retentionCartItems = await cs.getRetentionCartItems();
    }
}
