import Vue from 'vue';

const fallbackLocale = (window as any).CbxCulture ?? navigator.language;

Vue.filter('capitalize', (input: string) => {
    return input.toUpperCase();
});

Vue.filter('number', (input: number, decimals: number = 2, locale: string = fallbackLocale) => {
    return new Intl.NumberFormat(locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(input);
});

Vue.filter('currency', (input: number, currency: string, locale: string = fallbackLocale) => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(input);
});

Vue.filter('date', (input: any, type: string = 'medium', locale: string = fallbackLocale) => {
    let date: Date = new Date();

    try {
        date = new Date(input);
    } catch (e) {
        return input;
    }

    switch (type) {
        case 'tiny':
            return date.toLocaleDateString(locale, { year: 'numeric', month: 'numeric', day: 'numeric' });
        case 'short':
            return date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
        case 'medium':
            return date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
        case 'long':
            return `${date.toLocaleDateString(locale, { year: 'numeric', month: 'numeric', day: 'numeric' })} @ ${date.toLocaleTimeString()}`;
        case 'full':
            return `${date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' })} - ${date.toLocaleTimeString()}`;
    }
});

Vue.filter('time', (input: any, locale: string = fallbackLocale) => {
    let date: Date = new Date();

    try {
        date = new Date(input);
    } catch (e) {
        return input;
    }
    return date.toLocaleTimeString([locale]);
});

Vue.filter('formatBytes', (bytes: any, decimals: any, kib: any) => {
    kib = kib || false;
    if (bytes === 0) {
        return '0 Bytes';
    }
    if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) {
        return 'Not an number';
    }
    const k = kib ? 1024 : 1000;
    const dm = decimals || 2;
    const sizes = kib ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
});

