import ContentService from '@/services/ContentService';
import CategoryService from '@/services/CategoryService';
import { NavigationItem, NavigationItemResponse } from '@/models/Navigation';
import CacheService from '@/services/CacheService';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';

export default class MobileNavigationService {

    private contentService = new ContentService();
    private categoryService = new CategoryService();
    private cacheService = new CacheService();

    public async getBase(): Promise<NavigationItemResponse> {

        if (CbxVariablesModule.VARIABLES.enableCustomMainNavigtaion) {
            const content = await this.getCustomData();
            return {
                parent: null,
                children: [
                    ...content.children,
                ]
            };
        } else {
            const categories = await this.getCategoryDataWithParent();
            const content = await this.getContentDataWithParent();
            return {
                parent: null,
                children: [
                    ...categories.children,
                    ...content.children,
                ]
            };
        }
    }

    public async getCategoryData(parent: string = ''): Promise<NavigationItem[]> {
        try {
            return await this.categoryService.getChildren(parent);
        } catch (e) {
            return [];
        }
    }

    public async getCategoryDataWithParent(parent: string = '__root__'): Promise<NavigationItemResponse> {
        if (CbxVariablesModule.VARIABLES.disableCatalogue) {
            return {
                parent: null,
                children: [],
            };
        }

        try {
            const cacheKey = `getCategoryDataWithParent__${parent}_${CbxVariablesModule.VARIABLES.contextCacheKey}`;
            const cachedItem: NavigationItemResponse = await this.cacheService.getCachedItem(cacheKey);
            if (cachedItem) {
                return cachedItem;
            }
            try {
                const response: NavigationItemResponse = await this.categoryService.getChildrenWithParent(parent);
                this.cacheService.setCachedItem(cacheKey, response);
                return response;
            } catch (e) {
                return {
                    parent: null,
                    children: [],
                };
            }
        } catch (e) {
            console.log(e);
        }
    }

    public async getContentData(parent: string = ''): Promise<NavigationItem[]> {
        try {
            return await this.contentService.getChildren(parent);
        } catch (e) {
            return [];
        }
    }

    public async getContentDataWithParent(parent: string = '__root__'): Promise<NavigationItemResponse> {
        try {
            const cacheKey = `getContentDataWithParent__${parent}`;
            const cachedItem: NavigationItemResponse = await this.cacheService.getCachedItem(cacheKey);
            if (cachedItem) {
                return cachedItem;
            }
            const response: NavigationItemResponse = await this.contentService.getChildrenWithParent(parent);
            this.cacheService.setCachedItem(cacheKey, response);
            return response;
        } catch (e) {
            return {
                parent: null,
                children: [],
            };
        }
    }

    public async getCustomData(): Promise<NavigationItemResponse> {
        try {
            const cacheKey = `getCustomData_${CbxVariablesModule.VARIABLES.contextCacheKey}`;
            const cachedItem: NavigationItemResponse = await this.cacheService.getCachedItem(cacheKey);
            if (cachedItem) {
                return cachedItem;
            }
            try {
                const response: NavigationItemResponse = await this.contentService.getCustomContent();
                this.cacheService.setCachedItem(cacheKey, response);
                return response;
            } catch (e) {
                return {
                    parent: null,
                    children: [],
                };
            }
        } catch (e) {
            console.log(e);
        }
    }

    public handleBodyOverflow(value: boolean) {
        if (!value) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = null;
        }
    }
}
