const UIkit = window.UIkit;
import axios from "axios";

export default function () {

    function toggleSpinner(e) {
        if (e.classList.contains('uk-spinner')) {
            e.removeAttribute('uk-spinner');
            e.classList.remove('uk-spinner');
            e.querySelector('.uk-icon').classList.remove('uk-hidden');
        } else {
            e.querySelector('.uk-icon').classList.add('uk-hidden');
            e.setAttribute('uk-spinner', 'ratio: 0.4');
        }
    }

    // signin
    UIkit.util.on(document, 'submit', '#login-form', function (e) {

        const elem = e.target,
            errormsg = document.getElementById('login-error'),
            errormsgcritical = document.getElementById('login-critical-error'),
            action = elem.getAttribute('action'),
            formData = new FormData(elem),
            submitBtn = document.getElementById('login-btn');

        toggleSpinner(submitBtn);

        errormsg.classList.add('uk-hidden');
        errormsgcritical.classList.add('uk-hidden');

        axios.post(action, formData)
            .then(function (response) {
                try {
                    if (response.data.signincompleted === true) {
                        window.location.reload();
                    } else {
                        if (response.data.errortype === 1)
                            errormsgcritical.classList.remove('uk-hidden')
                        else
                            errormsg.classList.remove('uk-hidden');
                        toggleSpinner(submitBtn);
                    }
                } catch (e) {
                    errormsgcritical.classList.remove('uk-hidden');
                    toggleSpinner(submitBtn);
                }
            })
            .catch(function (error) {
                console.log(error);
                errormsgcritical.classList.remove('uk-hidden');
                toggleSpinner(submitBtn);
            });

        e.preventDefault();
    });

    // signout
    UIkit.util.on(document, 'click', '#signout', function (e) {

        const elem = e.target,
            href = elem.getAttribute('href');

        axios.post(href, { output: 'json' })
            .then(function (response) {
                if (response.data.signoutcompleted === true) {
                    window.location.reload();
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        e.preventDefault();
    });

    // reset password
    UIkit.util.on(document, 'submit', '#password-reset-form', function (e) {

        const elem = e.target,
            errormsg = document.getElementById('password-reset-error'),
            successmsg = document.getElementById('password-reset-success'),
            action = elem.getAttribute('action'),
            formData = new FormData(elem),
            submitBtn = document.getElementById('password-reset-btn');

            toggleSpinner(submitBtn);

        if (formData.get('output') === 'json') {

            errormsg.classList.add('uk-hidden');
            successmsg.classList.add('uk-hidden');

            axios.post(action, formData)
                .then(function (response) {
                    if (response.data.emailsent === true) {
                        successmsg.classList.remove('uk-hidden');
                    }
                    else {
                        errormsg.classList.remove('uk-hidden');
                    }

                    toggleSpinner(submitBtn);
                })
                .catch(function (error) {
                    console.log(error);

                    toggleSpinner(submitBtn);
                });

            e.preventDefault();
        }
    });
}