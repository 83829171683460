import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import CheckoutService from '@/services/CheckoutService';
import { CheckoutAddressEditModel, CheckoutDeliveryEditModel, CheckoutPaymentEditModel, DeliveryType, PaymentType, CheckoutCountryModel, CheckoutProcessOrderModel, DisabledDatesModel, AddressInfoDTO } from '@/models/Checkout';
import LoadingModule from './LoadingModule';
import { PakkeshopData } from '@/models/PackageShop';
import NewsletterService from '@/services/NewsletterService';
import { CbxVariablesModule } from './CbxVariablesModule';

@Module({
    store,
    dynamic: true,
    name: 'checkout',
    namespaced: true,
})
class CheckoutModule extends VuexModule {

    private checkoutService: CheckoutService = new CheckoutService();
    private newsletterService: NewsletterService = new NewsletterService();

    private address: CheckoutAddressEditModel = this.checkoutService.getCheckoutAddressEditModel();
    private addresses: AddressInfoDTO[] = [];
    private showNewsletterSubscription: boolean = false;
    private subscribedToNewsletter: boolean = false;
    private delivery: CheckoutDeliveryEditModel = this.checkoutService.getCheckoutDeliveryEditModel();
    private payment: CheckoutPaymentEditModel = this.checkoutService.getCheckoutPaymentEditModel();
    private process: CheckoutProcessOrderModel = this.checkoutService.getCheckoutProcessOrderModel();
    private deliveryTypes: DeliveryType[] = [];
    private deliveryDate: Date = null;
    private partialDeliveryNeeded: boolean = false;
    private partialDelivery: boolean = false;
    private disabledDeliveryDates: DisabledDatesModel = {mindate: null, maxdate: null, disableddates: [], disabledweekdays: []};
    private paymentTypes: PaymentType[] = [];
    private countries: CheckoutCountryModel[] = [];
    private addressIsValid: boolean = false;
    private deliveryIsValid: boolean = false;
    private paymentIsValid: boolean = false;
    private terms: string = '';

    get ADDRESS(): CheckoutAddressEditModel {
        return this.address;
    }
    get ADDRESSES(): AddressInfoDTO[] {
        return this.addresses;
    }
    get SHOW_NEWSLETTER_SUBSCRIPTION(): boolean {
        return this.showNewsletterSubscription;
    }
    get SUBSCRIBED_TO_NEWSLETTER(): boolean {
        return this.subscribedToNewsletter;
    }
    get DELIVERY(): CheckoutDeliveryEditModel {
        return this.delivery;
    }
    get PAYMENT(): CheckoutPaymentEditModel {
        return this.payment;
    }
    get PROCESS(): CheckoutProcessOrderModel {
        return this.process;
    }
    get DELIVERY_TYPES(): DeliveryType[] {
        return this.deliveryTypes;
    }
    get DELIVERY_DATE(): Date {
        return this.deliveryDate;
    }
    get DISABLED_DELIVERY_DATES(): DisabledDatesModel {
        return this.disabledDeliveryDates;
    }
    get PARTIAL_DELIVERY_NEEDED(): boolean {
        return this.partialDeliveryNeeded;
    }
    get PARTIAL_DELIVERY(): boolean {
        return this.partialDelivery;
    }
    get PAYMENT_TYPES(): PaymentType[] {
        return this.paymentTypes;
    }
    get COUNTRIES(): CheckoutCountryModel[] {
        return this.countries;
    }
    get ADDRESS_IS_VALID(): boolean {
        return this.addressIsValid;
    }
    get DELIVERY_IS_VALID(): boolean {
        return this.deliveryIsValid;
    }
    get PAYMENT_IS_VALID(): boolean {
        return this.paymentIsValid;
    }
    get TERMS(): string {
        return this.terms;
    }

    @Action({ commit: 'SET_ADDRESS' })
    public async GET_ADDRESS() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getAddress();
    }
    @Action({ commit: 'SET_ADDRESS' })
    public async UPDATE_ADDRESS(args: CheckoutAddressEditModel) {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.updateAddress(args);
    }
    @Action({ commit: 'SET_ADDRESSES' })
    public async GET_ADDRESSES() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getAddresses();
    }
    @Action({ commit: 'SET_SHOW_NEWSLETTER_SUBSCRIPTION' })
    public async GET_SHOW_NEWSLETTER_SUBSCRIPTION() {
        LoadingModule.SET_LOADING(true);
        if (!CbxVariablesModule.VARIABLES.showNewsletterSubscriptionInCheckout) return false;
        return await this.newsletterService.canSubscribe();
    }
    @Action({ commit: 'SET_SUBSCRIBED_TO_NEWSLETTER' })
    public async GET_SUBSCRIBED_TO_NEWSLETTER() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getSubscribedToNewsletter();
    }
    @Action({ commit: 'SET_SUBSCRIBED_TO_NEWSLETTER' })
    public async UPDATE_SUBSCRIBED_TO_NEWSLETTER(args: boolean) {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.updateSubscribedToNewsletter(args);
    }
    @Action({ commit: 'SET_DELIVERY' })
    public async GET_DELIVERY() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getDelivery();
    }
    @Action({ commit: 'SET_DELIVERY' })
    public async UPDATE_DELIVERY(args: CheckoutDeliveryEditModel) {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.updateDelivery(args);
    }
    @Action({ commit: 'SET_DELIVERY_DATE' })
    public async UPDATE_DELIVERY_DATE(args: Date) {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.updateDeliveryDate(args);
    }
    @Action({ commit: 'SET_PARTIAL_DELIVERY' })
    public async UPDATE_PARTIAL_DELIVERY(args: boolean) {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.updatePartialDelivery(args);
    }
    @Action({ commit: 'SET_PACKAGE_SHOP' })
    public async UPDATE_PACKAGE_SHOP(args: PakkeshopData) {
        LoadingModule.SET_LOADING(true);
        return args;
    }
    @Action({ commit: 'SET_PAYMENT' })
    public async GET_PAYMENT() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getPayment();
    }
    @Action({ commit: 'SET_PAYMENT' })
    public async UPDATE_PAYMENT(args: CheckoutPaymentEditModel) {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.updatePayment(args);
    }
    @Action({ commit: 'SET_PROCESS' })
    public async PROCESS_ORDER() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.processorder();
    }

    @Action({ commit: 'SET_DELIVERY_TYPES' })
    public async GET_DELIVERY_TYPES() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getDeliveryTypes();
    }
    @Action({ commit: 'SET_DELIVERY_DATE' })
    public async GET_DELIVERY_DATE() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getDeliveryDate();
    }
    @Action({ commit: 'SET_DISABLED_DELIVERY_DATES' })
    public async GET_DISABLED_DELIVERY_DATES() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getDisabledDeliveryDates();
    }
    @Action({ commit: 'SET_PARTIAL_DELIVERY_NEEDED' })
    public async GET_PARTIAL_DELIVERY_NEEDED() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getPartialDeliveryNeeded();
    }
    @Action({ commit: 'SET_PARTIAL_DELIVERY' })
    public async GET_PARTIAL_DELIVERY() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getPartialDelivery();
    }
    @Action({ commit: 'SET_PAYMENT_TYPES' })
    public async GET_PAYMENT_TYPES() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getPaymentTypes();
    }
    @Action({ commit: 'SET_COUNTRIES' })
    public async GET_COUNTRIES() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getCountries();
    }
    @Action({ commit: 'SET_TERMS' })
    public async GET_TERMS() {
        LoadingModule.SET_LOADING(true);
        return await this.checkoutService.getTerms();
    }

    @Mutation
    public async SET_ADDRESS(payload: CheckoutAddressEditModel) {
        this.address = payload;
        this.addressIsValid = await this.checkoutService.addressIsValid(this.address);
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public async SET_ADDRESSES(payload: AddressInfoDTO[]) {
        this.addresses = payload;
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public async SET_SHOW_NEWSLETTER_SUBSCRIPTION(payload: boolean) {
        this.showNewsletterSubscription = payload;
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public async SET_SUBSCRIBED_TO_NEWSLETTER(payload: boolean) {
        this.subscribedToNewsletter = payload;
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public async SET_DELIVERY(payload: CheckoutDeliveryEditModel) {
        this.delivery = payload;
        this.deliveryIsValid = await this.checkoutService.deliveryIsValid(this.delivery);
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public SET_PACKAGE_SHOP(payload: PakkeshopData) {
        this.delivery.extrafields['packageshop-shopid'] = payload?.shopid ?? '';
        this.delivery.extrafields['packageshop-name'] = payload?.shopname ?? '';
        this.delivery.extrafields['packageshop-address1'] = payload?.address1 ?? '';
        this.delivery.extrafields['packageshop-address2'] = payload?.address2 ?? '';
        this.delivery.extrafields['packageshop-zipcode'] = payload?.zip ?? '';
        this.delivery.extrafields['packageshop-city'] = payload?.city ?? '';
        this.delivery.extrafields['packageshop-country'] = payload?.country ?? '';
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public async SET_PAYMENT(payload: CheckoutPaymentEditModel) {
        this.payment = payload;
        this.paymentIsValid = await this.checkoutService.paymentIsValid(this.payment);
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public SET_PROCESS(payload: CheckoutProcessOrderModel) {
        this.process = payload;
        LoadingModule.SET_LOADING(false);
    }

    @Mutation
    public SET_DELIVERY_TYPES(payload: DeliveryType[]) {
        this.deliveryTypes = payload;
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public SET_DELIVERY_DATE(payload: Date) {
        this.deliveryDate = payload;
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public SET_DISABLED_DELIVERY_DATES(payload: DisabledDatesModel) {
        this.disabledDeliveryDates = payload;
        LoadingModule.SET_LOADING(false);
    }

    @Mutation
    public SET_PARTIAL_DELIVERY_NEEDED(payload: boolean) {
        this.partialDeliveryNeeded = payload;
        LoadingModule.SET_LOADING(false);
    }
    @Mutation
    public SET_PARTIAL_DELIVERY(payload: boolean) {
        this.partialDelivery = payload;
        LoadingModule.SET_LOADING(false);
    }

    @Mutation
    public SET_PAYMENT_TYPES(payload: PaymentType[]) {
        this.paymentTypes = payload;
        LoadingModule.SET_LOADING(false);
    }
    
    @Mutation
    public SET_COUNTRIES(payload: CheckoutCountryModel[]) {
        this.countries = payload;
        LoadingModule.SET_LOADING(false);
    }
    
    @Mutation
    public SET_TERMS(payload: string) {
        this.terms = payload;
        LoadingModule.SET_LOADING(false);
    }
}

export default getModule(CheckoutModule);