import { extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';

const eanRegex: RegExp = /^(\d{8}|\d{12,14})$/;

export const isValidEan = function(ean: string) {
    try {
        if (!eanRegex.test(ean)) return false; // early exit if no regex match
        ean = ean.padStart(14, '0'); // pad ean to 14 digits
        return ean
            .split('') // convert string to array
            .map((s, i) => Number(s) * ((i % 2 === 0) ? 3 : 1)) // multiply all digits by either 3 or 1
            .reduce((sum,i) => sum + i) // get the sum
            % 10 === 0 // the code is valid if the sum is divisible by 10
    } catch {
        return false;
    }
}

export const setupValidationRules = function() {
    extend('email', email);
    extend('true', {
        validate (value) {
          return {
            required: true,
            valid: value === true
          };
        },
        computesRequired: true
    });
    extend('ean', {
        validate (value) {
          return {
            required: true,
            valid: isValidEan(value)
          };
        },
        computesRequired: true
    });
    extend('required', {
        validate (value) {
          return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1
          };
        },
        computesRequired: true
    });
}
