
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import ProductCard from './utils/ProductCard.vue';
import ProductService from '../services/ProductService';
import { ProductSearchResultProduct, SimpleProductQueryRequestModel } from '../models/Product';
import isEqual from 'lodash/isEqual';

@Component({
    name: 'ProductSlider',
    components: {
        ProductCard,
    },
})
export default class ProductSlider extends Vue {
    @Prop({ type: Boolean, required: false, default: true }) private showDottedNav: boolean;
    @Prop({ type: Boolean, required: false, default: true }) private showArrows: boolean;
    @Prop({ type: Array, required: false, default: (): string[] => [] }) private productIds: string[];
    @Prop({ type: Object, required: false }) private query: SimpleProductQueryRequestModel;
    @Prop({ type: Boolean, required: false, default: true }) private fullWidth: boolean;
    @Prop({ type: Boolean, required: false, default: false }) private asSection: boolean;
    @Prop({ type: String, required: false, default: '' }) private headline: string;
    @Prop({ type: String, required: false, default: '' }) private description: string;
    @Prop({ type: Boolean, required: false, default: false }) private showheadingline: boolean;

    private ps: ProductService = new ProductService();
    private products: ProductSearchResultProduct[] = [];
    private ready: boolean = false;

    private async created() {
        if (this.productIds.length && !this.query) {
            this.products = await this.ps.getProductsById(this.productIds);
        } else if (this.query) {
            this.products = await this.ps.getProductsByQuery(this.query);
            const q = this.query as any
            if( q.Fields?.filter((f: any) => f.Field === "data.attributedata.popularity" && f.FieldType == "gt").length > 0 && this.products.length < 5)
            {
                this.products = [];
            }
        }
        this.ready = true;
    }

    @Watch('productIds')
    private async updateProducts(n: any, o: any) {
        if (!this.ready) return;      
        if (isEqual(n, o)) return;

        if (this.productIds.length === 0) this.products = [];
        else this.products = await this.ps.getProductsById(this.productIds);
    }
}
