import { Module, VuexModule, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import UserService from '@/services/UserService';
import { ChangePasswordRequest, RequestNewPasswordRequest, SignInRequest, SignInResponse } from '@/models/SignIn';
import { CbxVariablesModule } from './CbxVariablesModule';

@Module({
    store,
    dynamic: true,
    name: 'user',
    namespaced: true,
})
class UserModule extends VuexModule {

    private userService: UserService = new UserService();

    get IS_SIGNED_IN(): boolean {
        return CbxVariablesModule.VARIABLES.signinstatus;
    }

    get USER_ROLE(): string {
        return CbxVariablesModule.VARIABLES.userrole;
    } 
    
    @Action
    public async SIGN_IN(payload: SignInRequest): Promise<SignInResponse> {
        return await this.userService.signIn(payload);
    }

    @Action
    public async SIGN_OUT(): Promise<boolean> {
        return await this.userService.signOut();
    }

    @Action
    public async REQUEST_NEW_PASSWORD(payload: RequestNewPasswordRequest) {
        return await this.userService.requestNewPassword(payload);
    }

    @Action
    public async CHANGE_PASSWORD(payload: ChangePasswordRequest): Promise<string> {
        return await this.userService.changePasswordV2(payload);
    }

    @Action
    public GET_LOGIN_URL(): string {
        return this.userService.getLoginUrl();
    }
}

export default getModule(UserModule);