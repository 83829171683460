
import { Component, Vue } from 'vue-property-decorator';
import BarsIcon from '@/components/mobile-navigation/icons/BarsIcon.vue';
import { MobileNavigationModule } from '@/store/modules/MobileNavigationModule';

@Component({
    name: 'MobileNavigationButtonV2',
    components: {
        BarsIcon,
    },
})
export default class MobileNavigationButtonV2 extends Vue {
    private toggle() {
        MobileNavigationModule.TOGGLE_NAVIGATION();
    }
}
