import VueI18n from 'vue-i18n';
import TranslationService from '../services/TranslationService';
const translationService: TranslationService = new TranslationService();

export const loadLocale = async function(locale: string, i18n: VueI18n) {
    const localeMessages = await translationService.getTranslations(locale);
    i18n.setLocaleMessage(locale, localeMessages);
}

export const getI18n = (locale: string) => new VueI18n({
    locale: locale,
    missing: () => '•••',
});

export const getMissingHandler = (locale: string) => (...args: any[]) => {

    /*
        Handle missing translations by creating them,
        provided a default text is present.

        Output the default value if applicable.
    */
    
    const translationLocale = args?.[0] ?? '';
    const identifier = args?.[1] ?? '';
    const params = args?.[3] ?? [];
    const replacements = params?.[0] ?? [];
    const defaultText = replacements.pop() ?? '';
    const placeholderValue = '•••';

    if (translationLocale !== locale) {
        console.log(`%c Invalid args passed to translation `, 'background:#ff1e3d5e;color:white;display:inline-block;padding:2px;');
        return placeholderValue;
    }

    function format(input: string, args: []) {
        let retval = input;
        for (const k in args) {
            retval = retval.replace(new RegExp("\\{" + k + "\\}", 'g'), args[k]);
        }
        return retval;
    }

    if (defaultText.length > 0) {
        // Ensure translation
        const parts = identifier.split('.');
        const group = parts[0];
        const key = parts[1];
        if (group.length && key.length) {
            translationService.ensureTranslation({key, group, defaultText, replacements});
        }
        return format(defaultText, replacements);
    }
    return placeholderValue;
}

export const localeToLanguage = (localeString: string): string => {
    // returns ISO 639-1 format from a locale string
    return localeString.substr(0, 2);
}