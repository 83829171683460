import { NavigationItem, NavigationItemResponse } from '@/models/Navigation';
import axios, { AxiosResponse } from 'axios';

export default class CategoryService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/productcategories/children`;
    }

    public async getChildren(id: string = ''): Promise<NavigationItem[]> {
        try {
            const res: AxiosResponse<NavigationItemResponse> = await axios.get(`${this.endpoint}/${id}`);
            return res.data.children;
        } catch (e) {
            console.log(e);
        }
    }

    public async getChildrenWithParent(id: string = '__root__'): Promise<NavigationItemResponse> {
        if (id === '__root__') id = '';
        try {
            const res: AxiosResponse<NavigationItemResponse> = await axios.get(`${this.endpoint}/${id}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
}
