
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'Alert',
})
export default class Alert extends Vue {
    @Prop({ type: Boolean, required: false, default: false }) private display: string;
    @Prop({ type: String, required: false, default: null }) private icon: string;
    @Prop({ type: String, required: false, default: '' }) private message: string;
    @Prop({ type: String, required: false, default: '' }) private type: string;
}
